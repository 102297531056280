<template>
  <main class="tourism fs16">
    <h3 class="text-center my-2" v-if="isOpenPassportBlock">Order summary</h3>
    <div class="d-flex align-items-center justify-content-center weight-700 my-2">
      <p class="m-rl-20">Barcelona</p>
      <p>14/04/21</p>
      <img src="/assets/img/icons/flight.svg" alt="" class="mx-5">
      <p>18/04/21</p>
    </div>
    <MobileBannerCarousel></MobileBannerCarousel>
    <div class="bg-white p-relative p-2">
      <img src="/assets/img/icons/share-alt-light-black.svg" alt="" class="share-icon">
      <p class="text-center">
        <b>Flight details</b>
      </p>
      <MobileFlightDesData class="pb-5 bd-bottom">
        <template slot="additionalData">
          <img src="/assets/img/icons/flight connection.svg" alt="">
          <p>5h 40m</p>
        </template>
      </MobileFlightDesData>
      <MobileFlightDesData class="pb-5 mt-7">
        <template slot="additionalData">
          <img src="/assets/img/icons/flightDirect.svg" alt="">
          <p>4h 40m</p>
        </template>
      </MobileFlightDesData>
      <div class="d-flex justify-content-between detail-block">
        <button class="primary-btn bg-blue5 small-btn color-primary" @click="openDetailModal">
          <img src="/assets/img/icons/info-white.svg" alt="" class="mx-5">
          {{ $t( "Full details" ) }}
        </button>
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/info-white.svg" alt="" class="mx-2">
          <p>Commission: <span class="color-green5 weight-700">92 ₪</span></p>
        </div>
      </div>
    </div>
    <div class="p-2 d-flex fs14">
      <div class="bd-radius bdbr-1 df-c m-24 p-2 bg-white weight-700 w-100">
        Baggage info
      </div>
      <div class="bd-radius bdbr-1 df-c m-24 p-2 bg-white weight-700 w-100">
        General info
      </div>
      <div class="bd-radius bdbr-1 df-c m-24 p-2 bg-white weight-700 w-100">
        <img src="/assets/img/icons/info-white.svg" alt="" class="m-rl-10">
        <p>COVID-19</p>
      </div>
    </div>
    <div class="bg-white text-center py-5" v-if="!isOpenPassportBlock">
      3 Passengers: Add Passport details to continue
    </div>
    <div class="bg-white" v-else>
      <div class="d-flex justify-content-between align-items-center p-2">
        <p><b>Passengers Passport details</b></p>
        <img src="/assets/img/icons/times-light-black.svg" alt="">
      </div>
      <div class="d-flex justify-content-between flex-wrap passports bg-grey4">
        <TourPassport class="p-2"></TourPassport>
        <TourPassport class="p-2"></TourPassport>
        <TourPassport class="p-2"></TourPassport>
      </div>
    </div>
    <div class="p-2 my-5">
      <FlightResult :isShowTitle="true"></FlightResult>
    </div>
    <div class="d-flex justify-content-between mx-5" v-if="!isOpenPassportBlock">
      <button class="primary-btn primary-btn_sm bg-green6" @click="openPassportBlock()">
        {{ $t( "Book Flight" ) }}
      </button>
      <button class="primary-btn primary-btn_sm bg-red6">
        {{ $t( "Cancel" ) }}
      </button>
    </div>
    <div class="df-c" v-else>
      <button class="primary-btn primary-btn_sm bg-grey9">
        {{ $t( "Cancel" ) }}
      </button>
    </div>
    <MobileDetailModal></MobileDetailModal>
  </main>
</template>

<script>
import MobileFlightDesData from "../../../../components/tourism/MobileFlightDesData.vue";
import FlightResult from "../../../../components/tourism/flight/FlightResult.vue";
import MobileDetailModal from "../../../../components/tourism/flight/MobileDetailModal.vue";
import TourPassport from "../../../../components/tourism/TourPassport.vue";
import MobileBannerCarousel from "../../../../components/tourism/MobileBannerCarousel.vue";
export default {
  name: "FlightOrderReseller",
  components: {
    MobileFlightDesData,
    FlightResult,
    MobileDetailModal,
    TourPassport,
    MobileBannerCarousel
  },
  data() {
    return {
      settings: {
        "dots": true,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      },
      isOpenPassportBlock: false
    }
  },
  methods: {
    openDetailModal() {
      this.$modal.show("MobileDetailModal");
    },
    openPassportBlock() {
      this.isOpenPassportBlock = true;
    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/tourism.scss";

</style>

<style lang="scss" scoped>
.mobile-result-btn {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}
.passports {
  padding: 10px 15px;
}

.share-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 18px;
}

.detail-block {
  margin: 15px 10px 5px;
}
</style>